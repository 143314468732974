<template>
  <fki-sub-nav
    is-active
    :color="page.color"
    :items="items"
  />
  <fki-container
    fixed
    column
    top="196px"
    left="0"
    width="100%"
    class="fki-services-page"
  >
    <fki-container-header
      v-if="$route.path === '/creative/services'"
      :color="page.color"
      min-height="100px"
      :title="page.body"
      contact-target="production"
      has-contact
      @openContact="openContact"
    />
    <router-view
      name="services"
      :slides="page.slides"
      :services="services"
    />
    <router-view
      :colorists="colorists"
      name="colorists"
      :color="page.color"
      @click="selectColorist"
      @openContact="openContact"
    />
    <fki-footer is-active>
      <fki-footer-nav is-active />
    </fki-footer>
  </fki-container>
  <fki-container-popup
    :is-active="showPopup"
    overlay
    persistent
    color="#424242"
    max-width="1030px"
    min-height="578px"
    @close="closePopup()"
  >
    <fki-colorist
      :colorist="currentColorist"
      :color="page.color"
      @openContact="openContact"
    />
  </fki-container-popup>
  <fki-container-popup
    :is-active="showContactPopup"
    overlay
    persistent
    has-padding
    color="#424242"
    @close="closeContact()"
  >
    <fki-contact-form />
  </fki-container-popup>
</template>

<script>
// @ is an alias to /src
import Api from '@/services/api'
import { FKISubNav, FKIFooterNav } from '@/components/FKINav'
import { FKIContainer, FKIContainerHeader, FKIContainerPopup } from '@/components/FKIContainer'
import { FKIColorist } from '@/components/FKITalent'
import { FKIContactForm } from '@/components/FKIContact'
import { FKIFooter } from '@/components/FKIApp'

export default {
  name: 'Creative',
  components: {
    'fki-sub-nav': FKISubNav,
    'fki-container': FKIContainer,
    'fki-container-header': FKIContainerHeader,
    'fki-container-popup': FKIContainerPopup,
    'fki-colorist': FKIColorist,
    'fki-footer': FKIFooter,
    'fki-footer-nav': FKIFooterNav,
    'fki-contact-form': FKIContactForm
  },
  emits: ['isLoading', 'setBackgrounds'],
  data () {
    return {
      showPopup: false,
      showContactPopup: false,
      showEmailResponsePopup: false,
      messageReponse: '',
      page: { color: 'green', body: 'THE ART OF POST - IMAGE, COLOR, & SOUND', slides: [] },
      items: [{
        name: 'services',
        color: 'green',
        slug: 'creative/services'
      },
      {
        name: 'colorists',
        color: 'green',
        slug: 'creative/colorists'
      }],
      currentColorist: {},
      colorists: [],
      services: []
    }
  },
  async created () {
    if (this.$route.name === 'Creative') {
      this.$router
        .push({
          path: '/creative/services'
        })
        .catch(() => {})
    }
    await this.getPage()
    await this.getServices(this.page.categoryIdentifier)
    await this.getColorists()
  },
  methods: {
    async checkParams () {
      if (this.$route.name === 'Creative') {
        this.$router
          .push({
            path: '/creative/services'
          })
          .catch(() => {})
      }
      await this.getPage()
      await this.getServices(this.page.categoryIdentifier)
    },

    async getPage () {
      this.isLoading = true

      try {
        this.$emit('isLoading', true)
        const { data: result } = await Api().get('/page/s/creative')
        this.page = result
        this.page.slides.forEach(element => {
          element.src = element.src.replace('image/', '')
        })
      } catch (err) {
        console.log(err)
      } finally {
        this.isLoading = false
        this.$emit('isLoading', false)
      }
    },
    async getColorists () {
      this.isLoading = true

      try {
        this.$emit('isLoading', true)
        const { data: result } = await Api().get('/colorists')
        this.colorists = result
        this.colorists.forEach(element => {
          element.image.src = element.image.src.replace('image/', '')
          element.past.forEach(item => {
            item.image.src = item.image.src.replace('image/', '')
          })
        })
      } catch (err) {
        console.log(err)
      } finally {
        this.isLoading = false
        this.$emit('isLoading', false)
      }
    },
    async getServices (categoryIdentifier) {
      this.isLoading = true

      try {
        this.$emit('isLoading', true)

        const { data: result } = await Api().get('/services/c/' + categoryIdentifier)
        this.services = result
      } catch (err) {
        console.log(err)
      } finally {
        this.isLoading = false
        this.$emit('isLoading', false)
      }
    },
    closePopup () {
      this.showPopup = false
      this.currentColorist = {}
    },
    closeContact () {
      this.showContactPopup = false
    },
    openContact (item) {
      this.showContactPopup = true
    },
    selectColorist (colorist) {
      this.currentColorist = colorist
      this.showPopup = true
    },
    closeEmailResponse () {
      this.showEmailResponsePopup = false
    },
    openEmailResponse (item) {
      this.showEmailResponsePopup = true
    }
  }
}
</script>
<style lang="scss" scoped>
  .fki-services-page {
    bottom:0;
  }
</style>
